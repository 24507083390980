import React, { useRef, useState, useLayoutEffect } from 'react'
import throttle from 'lodash/throttle'

import windowSize from '../hooks/windowSize'

import './FloatingImage.scss'

import FadeIn from './FadeIn'

const FloatingImage = ({ children }) => {
  const { innerHeight, innerWidth } = windowSize()

  const ref = useRef(null)

  const [variable, setVariable] = useState(0)

  const animateEl = () => {
    const refPos = ref.current.getBoundingClientRect().top
    const refHeight = ref.current.getBoundingClientRect().height

    const viewPos = (refPos + refHeight / 2) / innerHeight

    if (viewPos < 1.3 && viewPos > -0.3) {
      window.requestAnimationFrame(() => {
        setVariable(1 - viewPos)
      })
    }
  }

  const handleAnimateEl = throttle(animateEl, 10)

  useLayoutEffect(animateEl)

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleAnimateEl, { passive: true })
    return () => {
      window.removeEventListener('scroll', handleAnimateEl, { passive: true })
    }
  }, [handleAnimateEl])

  return (
    <FadeIn duration={2} className="image">
      <div className="floating-image" ref={ref}>
        {children}
        <div className="decorations">
          <span
            style={{
              transform: `translate3d(${0 - (variable * 2 + 2)}rem,${
                innerWidth > 767 ? `${variable * 2 + 2}rem` : `1.5rem`
              },0)`,
            }}
          />
          <span
            style={{
              transform: `translate3d(${variable * 2 + 2}rem,${
                innerWidth > 767 ? `${0 - (variable * 2 + 2)}rem` : `-1.5rem`
              },0)`,
            }}
          />
        </div>
      </div>
    </FadeIn>
  )
}

export default FloatingImage
