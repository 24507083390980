import React, { useRef, useState, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import throttle from 'lodash/throttle'

import windowSize from '../hooks/windowSize'

import './ParallaxHero.scss'

const Parallax = ({ children, factor }) => {
  const { innerHeight } = windowSize()
  const parallaxWrap = useRef(null)
  const parallaxContent = useRef(null)
  const [wrapHeight, setWrapHeight] = useState(0)
  const [contentHeight, setContentHeight] = useState(wrapHeight + innerHeight)
  const heightFactor = 1 + factor

  const setHeights = () => {
    setWrapHeight(
      parallaxWrap.current.parentElement.parentElement.getBoundingClientRect()
        .height
    )
    setContentHeight(
      Math.round(Math.max(wrapHeight, innerHeight) * heightFactor)
    )
  }

  useLayoutEffect(() => {
    setHeights()
  })

  const animateEl = () => {
    const wrapPos = parallaxWrap.current.parentElement.parentElement.getBoundingClientRect()
      .top

    const calcOffset = wrapPos * heightFactor * 0.15
    const percentComplete =
      (0 - calcOffset) /
      (contentHeight - Math.round(Math.max(wrapHeight, innerHeight)))
    if (percentComplete <= 1) {
      window.requestAnimationFrame(() => {
        parallaxContent.current.style.transform = `translate3d(0, ${calcOffset}px, 0)`
        parallaxWrap.current.style.opacity = 1 - percentComplete
      })
    } else {
      parallaxWrap.current.style.opacity = 0
    }
  }

  const handleAnimateEl = throttle(animateEl, 10)

  useLayoutEffect(animateEl)
  useLayoutEffect(() => {
    window.addEventListener('scroll', handleAnimateEl, { passive: true })
    return () => {
      window.removeEventListener('scroll', handleAnimateEl, { passive: true })
    }
  }, [handleAnimateEl])

  return (
    <div className="parallax-bg-wrap">
      <div
        className={'parallax-bg'}
        ref={parallaxWrap}
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          width: '100%',
          height: '100%',
          willChange: 'opacity',
        }}
      >
        <div
          className="parallax-bg-content"
          ref={parallaxContent}
          style={{
            position: 'relative',
            width: '100%',
            height: contentHeight + 'px',
            animation: 'fade-in 1s ease',
            willChange: 'transform',
          }}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

Parallax.defaultProps = {
  factor: 0.125,
}

Parallax.propTypes = {
  children: PropTypes.node.isRequired,
  factor: PropTypes.number,
}

export default Parallax
