import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import ParallaxHero from '../components/ParallaxHero'
import Divider from '../components/Divider'
import FloatingImage from '../components/FloatingImage'
import FadeIn from '../components/FadeIn'

import SPCP from '../assets/certifications/spcp.svg'
import CPCP from '../assets/certifications/cpcp.svg'

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      intro_background: file(relativePath: { eq: "denovo-background.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1800, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image_1: file(relativePath: { eq: "cosmetic-eyeliner-tattooing.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image_2: file(relativePath: { eq: "laurinda-silva-giffin.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Home" />
      <section id="intro">
        <FadeIn className="text">
          <h1>Look beautiful, 24 hours a day</h1>
          <p>
            Nearly everyone can enjoy the effects of cosmetic tattooing. From
            athletic types that want to look their best during various physical
            activities, to those with busy lives and lifestyles who no longer
            want to spend valuable time applying makeup, or people with physical
            impairments that have difficulties with the task of makeup
            application.
          </p>
          <p>
            If you would like to feel confident about your appearance every day,
            contact us today for a <em>free consultation</em>.
          </p>
          <Link className="button" to="/contact">
            Book an appointment
          </Link>
        </FadeIn>
        <ParallaxHero>
          <Img fluid={data.intro_background.childImageSharp.fluid} />
        </ParallaxHero>
      </section>
      <Divider style={{ paddingTop: '2rem' }} />
      <section id="tattooing-info">
        <FloatingImage>
          <Img
            fluid={data.image_1.childImageSharp.fluid}
            alt="Laurinda Silva Giffin performing permanent cosmetic tattooing on a client at De Novo Permanent Cosmetics"
          />
        </FloatingImage>
        <FadeIn className="text">
          <h2>What are permanent cosmetics?</h2>
          <p>
            Permanent cosmetics are tattooing procedures which implant tiny
            drops of pigment into the dermis. Up-to-date topicals are used to
            minimize discomfort during procedures.
          </p>
          <p>
            The type of permanent cosmetics applied will vary from person to
            person depending on their coloring and individual needs. Your
            personal care and attention begins with a complimentary consultation
            and ends with a finished product we can both be proud of.
          </p>
          <Link className="button" to="/services">
            <span>Learn about our services</span>
          </Link>
        </FadeIn>
      </section>
      <Divider />
      <section id="about-denovo">
        <div className="row">
          <FadeIn className="text">
            <h2>About De Novo</h2>
            <p>
              Laury Giffin, owner and operator of De Novo, has extensive training
              and experience in the field of permanent cosmetics and
              electrolysis, successfully combining her artistic talent and
              compassion to provide the best result for her clients. With over
              thirty years of industry experience, we understand every aspect of
              the permanent cosmetic procedure. Laury trained under acclaimed
              tattooer Mary Jane Haake, owner of Dermigraphics, LLC and Dermal
              Source in Portland, Oregon. Laury is a nationally recognized
              Certified Professional Electrologist as well as a Certified
              Permanent Cosmetics Professional.
            </p>
          </FadeIn>
          <FloatingImage>
            <Img
              fluid={data.image_2.childImageSharp.fluid}
              alt="Laurinda Silva Giffin is the owner and operator of De Novo Permanent Cosmetics"
            />
          </FloatingImage>
        </div>
        <FadeIn className="row" id="certifications">
          <div className="image">
            <img
              src={SPCP}
              alt="Society of Permanent Cosmetic
              Professionals certification"
            />
            <img src={CPCP} alt="Certified Permanent Cosmetic Professional" />
          </div>
        </FadeIn>
        <div className="row" style={{ alignItems: 'flex-start' }}>
          <FadeIn className="text">
            <p>
              Laury is a member of the Society of Permanent Cosmetic
              Professionals as a Certified Permanent Cosmetic Professional.
              Active members of this association must meet the highest standards
              for safety, ethics, and technical ability. You can be confident
              that Laury is held in the highest regard by both medical and
              beauty communities.
            </p>
          </FadeIn>
          <FadeIn className="text">
            <p>
              Laury has performed thousands of permanent cosmetic and
              electrolysis procedures. Along with being a nationally certified
              and recognized technician, she has attended dozens of
              international cosmetic tattoo conventions and has conducted
              symposiums for advanced work, which draw people from around the
              world. Laury also specializes in working on women following
              mastectomies for breast cancer and has expertise in repigmentation
              of scars.
            </p>
          </FadeIn>
        </div>
        <div className="row">
          <FadeIn className="text">
            <p>
              For her work in areola repigmentation for breast cancer survivors,
              Laury was recently the subject of an{' '}
              <a
                href="http://projects.columbian.com/2018/02/25/fighting-breast-cancer-a-momentary-sting-to-obscure-the-scars/"
                target="_blank"
                rel="noopener noreferrer"
              >
                article in The Columbian
              </a>
              .
            </p>
          </FadeIn>
        </div>
      </section>
      <Divider />
    </Layout>
  )
}
export default IndexPage
